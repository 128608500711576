.landing-page-container {
  width: 100%;
  height: auto;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.landing-page-top-container {
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1633432695903-9ef31eec6a05?q=80&w=1500&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}
.landing-page-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-logo {
  color: #ffffff;
}
.landing-page-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-text {
  color: #ffffff;
  margin-right: var(--dl-space-space-doubleunit);
}
.landing-page-text01 {
  color: #ffffff;
  margin-right: var(--dl-space-space-doubleunit);
}
.landing-page-text02 {
  color: #fffcfc;
  display: none;
  margin-right: var(--dl-space-space-doubleunit);
}
.landing-page-text03 {
  color: #ffffff;
  margin-right: var(--dl-space-space-doubleunit);
}
.landing-page-link {
  display: contents;
}
.landing-page-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-burger-menu1 {
  width: 24px;
  height: 24px;
}
.landing-page-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.landing-page-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-icon03 {
  width: 24px;
  height: 24px;
}
.landing-page-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text04 {
  margin-bottom: 8px;
}
.landing-page-text05 {
  margin-bottom: 8px;
}
.landing-page-text06 {
  margin-bottom: 8px;
}
.landing-page-link01 {
  display: contents;
}
.landing-page-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text08 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link02 {
  display: contents;
}
.landing-page-icon05 {
  width: 24px;
  height: 24px;
}
.landing-page-link03 {
  display: contents;
}
.landing-page-icon07 {
  width: 24px;
  height: 24px;
}
.landing-page-link04 {
  display: contents;
}
.landing-page-icon09 {
  width: 24px;
  height: 24px;
}
.landing-page-hero {
  width: 100%;
  height: 375px;
  display: flex;
  max-width: 1000px;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.landing-page-content-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.landing-page-text09 {
  color: #ffffff;
  font-size: 3rem;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 1.55;
  text-transform: none;
  text-decoration: none;
}
.landing-page-subheading {
  color: rgb(255, 255, 255);
}
.landing-page-text14 {
  color: #ffffff;
  padding-top: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-doubleunit);
}
.landing-page-main {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-quadruple);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-quadruple);
  justify-content: flex-start;
}
.landing-page-text16 {
  color: var(--dl-color-gray-500);
  display: none;
}
.landing-page-cards-container {
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-doubleunit);
  flex-wrap: wrap;
  max-width: 1000px;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-link05 {
  display: contents;
}
.landing-page-component3 {
  text-decoration: none;
}
.landing-page-link06 {
  display: contents;
}
.landing-page-component4 {
  text-decoration: none;
}
.landing-page-link07 {
  display: contents;
}
.landing-page-component5 {
  text-decoration: none;
}
.landing-page-footer {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.landing-page-menu {
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-links-container2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-container1 {
  display: none;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.landing-page-link08 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.landing-page-link09 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.landing-page-link10 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.landing-page-link11 {
  text-decoration: none;
}
.landing-page-container2 {
  display: flex;
  align-items: flex-start;
  margin-left: 100px;
  flex-direction: column;
}
.landing-page-link12 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.landing-page-link13 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.landing-page-link14 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.landing-page-link15 {
  text-decoration: none;
}
.landing-page-follow-container1 {
  display: flex;
  align-items: center;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
}
.landing-page-text17 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.landing-page-icons-container1 {
  width: 100px;
  display: none;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.landing-page-icon11 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.landing-page-icon13 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
.landing-page-icon15 {
  fill: var(--dl-color-gray-white);
  width: 24px;
  height: 24px;
}
@media(max-width: 991px) {
  .landing-page-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
  .landing-page-cards-container {
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .landing-page-right-side {
    display: none;
  }
  .landing-page-burger-menu {
    display: flex;
  }
  .landing-page-hero {
    justify-content: center;
  }
  .landing-page-content-container {
    align-items: center;
  }
  .landing-page-text09 {
    font-size: 2.5rem;
  }
  .landing-page-text14 {
    text-align: center;
  }
  .landing-page-main {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .landing-page-menu {
    flex-direction: column;
  }
  .landing-page-links-container2 {
    margin-top: var(--dl-space-space-tripleunit);
    margin-bottom: var(--dl-space-space-tripleunit);
    justify-content: space-between;
  }
  .landing-page-container1 {
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .landing-page-text09 {
    font-size: 2rem;
    text-align: center;
  }
  .landing-page-subheading {
    font-size: 1.3rem;
    text-align: center;
  }
  .landing-page-links-container2 {
    margin-top: var(--dl-space-space-doubleunit);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .landing-page-link08 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-link09 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-link10 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-link11 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-container2 {
    height: 100%;
    align-items: flex-start;
    margin-left: 50px;
  }
  .landing-page-link12 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-link13 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-link14 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-link15 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .landing-page-follow-container1 {
    margin-left: 0px;
  }
}
